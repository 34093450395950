import React, { useState } from "react";
import "../assets/styles/Contact.scss";

function Contact() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const [nameError, setNameError] = useState(false);
  const [emailError, setEmailError] = useState(false);
  const [messageError, setMessageError] = useState(false);

  const validateForm = (e: { preventDefault: () => void }) => {
    // Prevent form submission if validation fails
    if (!name.trim() || !email.trim() || !message.trim()) {
      e.preventDefault();

      // Validate fields
      setNameError(!name.trim());
      setEmailError(!email.trim());
      setMessageError(!message.trim());
    }
  };

  return (
    <div className="contact_wrapper">
      <h1>Contact Me</h1>
      <form
        className="contact-form"
        action="https://formsubmit.co/anshulkarande20@gmail.com"
        method="POST"
        onSubmit={validateForm}
      >
        {/* Name Input */}
        <label htmlFor="name">Your Name</label>
        <input
          id="name"
          type="text"
          name="name"
          placeholder="Enter your name"
          value={name}
          onChange={(e) => setName(e.target.value)}
          style={{ borderColor: nameError ? "red" : "" }}
          required
        />
        {nameError && <small style={{ color: "red" }}>Name is required</small>}

        {/* Email Input */}
        <label htmlFor="email">Email</label>
        <input
          id="email"
          type="email"
          name="email"
          placeholder="Enter your email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          style={{ borderColor: emailError ? "red" : "" }}
          required
        />
        {emailError && (
          <small style={{ color: "red" }}>Email is required</small>
        )}

        {/* Message Input */}
        <label htmlFor="message">Message</label>
        <textarea
          id="message"
          name="message"
          rows={5}
          placeholder="Write your message here"
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          style={{ borderColor: messageError ? "red" : "" }}
          required
        ></textarea>
        {messageError && (
          <small style={{ color: "red" }}>Message is required</small>
        )}

        {/* Submit Button */}
        <button type="submit">Send</button>
      </form>
    </div>
  );
}

export default Contact;
