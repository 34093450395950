import React from "react";
import hyperspaceHavoc from "../assets/images/hyperspaceHavoc.jpg";
import "../assets/styles/Project.scss";

function Project() {
  return (
    <div className="projects-container" id="projects">
      <h1>Personal Projects</h1>
      <div className="projects-grid">
        <div className="project">
          <a
            href="https://apps.apple.com/ca/app/hyperspace-havoc/id6504704988"
            target="_blank"
            rel="noreferrer"
          >
            <img
              src={hyperspaceHavoc}
              className="zoom"
              alt="thumbnail"
              width="150px"
              height="150px"
            />
          </a>
          <a
            href="https://apps.apple.com/ca/app/hyperspace-havoc/id6504704988"
            target="_blank"
            rel="noreferrer"
          >
            <h2>Hyperspace Havoc</h2>
          </a>
          <p>Developed a space shooter game using SwiftUI.</p>
        </div>
      </div>
    </div>
  );
}

export default Project;
